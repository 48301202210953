import {html, css, LitElement} from 'lit';

export class NumberInput extends LitElement {
    static get styles() {
        return css`
    #wrapper {
        display: flex;
    }
    button, input {
        display: inline-block;
        box-sizing: border-box;
        font-size: 1rem;
    }
    
    button {
        width: 1.5em;
        height: 1.5em;
    }
    input {
        width: 2em;
        height: 1.5em;
        text-align: center;
        font-family: Neucha, sans-serif;
        font-weight: bold;
    }
    .border {
        border: 2px solid #41403e;
        border-color: #666666;
        border-bottom-left-radius: 15px 66px;
        border-bottom-right-radius: 42px 15px;
        border-top-left-radius: 55px 15px;
        border-top-right-radius: 15px 75px;
    }
    .border-1 {
        border-bottom-left-radius: 75px 15px;
        border-bottom-right-radius: 15px 88px;
        border-top-left-radius: 15px 70px;
        border-top-right-radius: 147px 15px;
    }
    .border-2 {
        border-bottom-left-radius: 85px 15px;
        border-bottom-right-radius: 15px 95px;
        border-top-left-radius: 15px 142px;
        border-top-right-radius: 69px 15px;
    }
    `;
    }

    static get properties() {
        return {
            value: {type: Number},
            min: {type: Number},
            max: {type: Number}
        }
    }

    constructor() {
        super();
        this.value = 0;
    }

    set value(value) {

        if (isNaN(value)) return;

        if (value == "")
            value = this.default || 0;

        if (this.min != null)
            value = Math.max(this.min, value);
        if (this.max != null)
            value = Math.min(this.max, value);
        value = Math.round(value);

        this._value = value;
        this.requestUpdate('value');
        this.dispatchEvent(new Event('change'));
    }
    get value() {
        return this._value;
    }

    render() {
        return html`
        <div id="wrapper">
            <button class="stepButton border border-1 minus" @click="${() => this.value--}">-</button>
            <input class="border border-2" type="number" maxlength="4" .value="${this.value}" @change=${e => this.value = e.target.value} @keyup=${e => this.value = e.target.value} @input=${e => this.value = e.target.value} >
            <button class="stepButton border border-1 plus" @click="${() => this.value++}">+</button>
        </div>`;
    }
}

customElements.define('number-input', NumberInput);




//
// const template = document.createElement('template');
// template.innerHTML = `
//     <style>
//
//
//     </style>
//     <div id="wrapper">
//         <button class="stepButton border border-1 minus">-</button>
//         <input class="border border-2" type="number" maxlength="4">
//         <button class="stepButton border border-1 plus">+</button>
//     </div>
// `;
//
// export default class NumberInput extends HTMLElement {
//     static get observedAttributes() {
//         return ["value", "min", "max"];
//     }
//
//     set value(value) {
//         if (isNaN(value)) return;
//         if (this._min != null)
//             value = Math.max(this._min, value);
//         if (this._max != null)
//             value = Math.min(this._max, value);
//         value = Math.round(value);
//
//         this._value = value;
//         this.inputField.value = this._value;
//         this.dispatchEvent(new CustomEvent('valueChange', { detail: this._value }));
//     }
//     get value() {
//         return this._value;
//     }
//
//     set min(value) {
//         if (isNaN(value)) return;
//         this._min = value;
//         this.inputField.value = Math.max(this._value, this._min);
//     }
//
//     set max(value) {
//         if (isNaN(value)) return;
//         this._max = value;
//         this.inputField.value = Math.min(this._value, this._max);
//     }
//
//     constructor() {
//         super();
//         this.attachShadow({ mode: 'open' });
//         this.shadowRoot.appendChild(template.content.cloneNode(true));
//
//         this.inputField = this.shadowRoot.querySelector("input");
//         this.minusButton = this.shadowRoot.querySelector(".minus");
//         this.plusButton = this.shadowRoot.querySelector(".plus");
//         this.inputField.addEventListener('change', e => this.value = this.inputField.value);
//         this.minusButton.addEventListener('click', e => this.value--);
//         this.plusButton.addEventListener('click', e => this.value++);
//
//         this._value = 0;
//         this._min = null;
//         this._max = null;
//     }
//
//     attributeChangedCallback(attrName, oldValue, newValue) {
//         if (attrName === "value") {
//             this.value = parseInt(newValue);
//         } else if (attrName == "min") {
//             this.min = parseInt(newValue);
//         } else if (attrName == "max") {
//             this.max = parseInt(newValue);
//         }
//     }
//
// }
//
// customElements.define('number-input', NumberInput);