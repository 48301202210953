import GameCanvas from "./GameCanvas";

export default class Game {
    canvas;
    players;
    currentPlayer;
    isFinished;
    editMode;
    editPlayer;

    constructor() {
        this.canvas = new GameCanvas(this);
        this.players = [];
        this.isFinished = true;
        this.editMode = false;
        this.editPlayer = null;
    }

    onLoad() {
        if (this.isFinished)
            this.startNewGame(APP.playerSelect.getSelectedPlayers());
    }

    render() {
        const html = this.players.map(player => player.renderGame()).join(" ");
        document.getElementById("playertable").innerHTML = html;
        if (this.editMode)
            document.getElementById("playertable").innerHTML += "<div id='newScoreInfo' class='border paper-btn'>Okay, neue Zahl wählen:</div>";
    }

    setFinished(isFinished) {
        this.isFinished = isFinished;
    }

    startNewGame(players) {
        this.setFinished(false);
        this.canvas.reset();

        this.players = players;
        const n = this.players.length;
        for (let i=0; i < n; i++) {
            this.players[i].gamePlayersIndex = i;
            this.players[i].prev = this.players[(i-1) < 0 ? n-1 : i-1];
            this.players[i].next = this.players[(i+1)%n];
            this.players[i].resetPoints();
        }
        this.currentPlayer = this.players[0];
        this.currentPlayer.setCurrent(true);
        this.render();
    }

    getNextPlayer(player) {
        let nextPlayer = player.next;
        while (nextPlayer.isFinished)
            nextPlayer = nextPlayer.next;

        return nextPlayer;
    }

    nextPlayer() {
        this.checkForGameEnd();

        if (!this.isFinished) {
            this.currentPlayer.isCurrent = false;
            this.currentPlayer = this.getNextPlayer(this.currentPlayer);
            this.currentPlayer.isCurrent = true;
        }

        this.updateCircles();

        this.render();
        const currentPlayerNode = document.querySelector(".player.current");
        currentPlayerNode.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }

    clickOnWoodHandler(n) {
        if (!this.isFinished) {
            if (this.editMode) {
                this.editPlayer.saveScore(n);
                this.editMode = false;
                this.checkRules(this.editPlayer);
                this.render();
            } else {
                this.currentPlayer.saveScore(n);

                this.checkRules(this.currentPlayer);

                this.nextPlayer();
            }
        }
    }

    editScore(gamePlayersIndex) {
        console.log("EDIT", this.players[gamePlayersIndex]);
        this.editMode = true;
        this.editPlayer = this.players[gamePlayersIndex];
        this.editPlayer.deleteLastScore();
        this.render();
    }

    getPlayingPlayers() {
        return this.players.filter(player => !player.isFinished)
    }

    checkForGameEnd() {
        if (this.getPlayingPlayers().length <= 1) {
            console.log("ENDE!");
            this.isFinished = true;
        }
    }

    updateCircles() {
        const playingPlayers = this.getPlayingPlayers();
        const rules = APP.rulesSelect.rules;

        // Get points needed for current player to zap others
        let zap = [];
        if (rules.zapping.enabled) {
            zap = playingPlayers
                .filter(player => player.points > this.currentPlayer.points && player.points <= this.currentPlayer.points + 12)
                .map(player => player.points - this.currentPlayer.points + 1);
        }

        // Get required points to finish for current player
        let finish = [];
        const requiredPoints = rules.requiredPoints.value;
        if (this.currentPlayer.points >= requiredPoints - 12)
            finish = [requiredPoints - this.currentPlayer.points+1];

        // Get required points for other players that can finish next round
        const othersFinish = playingPlayers
            .filter(player => player != this.currentPlayer && player.points >= requiredPoints - 12)
            .map(player => requiredPoints - player.points + 1);

        this.canvas.markCircles(zap, finish, othersFinish);
    }

    checkRules(playerToCheck) {
        const rules = APP.rulesSelect.rules;
        // Check Win or OverThrow:
        const overThrowPunished = rules.overThrowPunished;
        if (overThrowPunished.enabled && playerToCheck.points > rules.requiredPoints.value) {
            playerToCheck.dropPoints(overThrowPunished.type, overThrowPunished.value);
            console.log(playerToCheck.name + " overthrowed");
        } else if (playerToCheck.points >= rules.requiredPoints.value) {
            playerToCheck.setFinished(true);
            this.checkForGameEnd();
            if (!this.isFinished) {
                const keepPlaying = window.confirm(playerToCheck.name + " wins! Keep playing?");
                console.log(keepPlaying);
                if (!keepPlaying)
                    this.setFinished(true);
            }
        }

        // Check xZero:
        const xZero = rules.xZero;
        const xZeroTimes = rules.xZeroTimes.value;
        const scores = playerToCheck.scores;
        if (xZero.enabled && scores.length > xZeroTimes && scores.slice(scores.length-1)[0] === 0) {
            let isXZero = false;
            if (xZero.checkType == "total") {
                const totalZeros = scores.reduce((zeros, score) => zeros += score === 0 ? 1 : 0, 0);
                isXZero = totalZeros % xZeroTimes == 0;
            } else if (xZero.checkType == "inRow") {
                const lastScores = scores.slice(scores.length - xZeroTimes);
                const lastScoresSum = lastScores.reduce((sum, score) => sum + score, 0);
                if (lastScoresSum === 0)
                    isXZero = true;
            }

            if (isXZero) {
                playerToCheck.dropPoints(xZero.type, xZero.value);
                console.log(playerToCheck.name + " has xZero");
            }
        }

        // Check Zapping:
        const zapping = rules.zapping;
        if (zapping.enabled && playerToCheck.points > 0) {
            const zappedPlayer = this.players.find(player => player.gamePlayersIndex !== playerToCheck.gamePlayersIndex && player.points === playerToCheck.points);
            if (zappedPlayer) {
                zappedPlayer.dropPoints(zapping.type, zapping.value);
                console.log(playerToCheck.name + " zapped " + zappedPlayer.name);
            }
        }


    }
}