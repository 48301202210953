export default class Nav {
    domElement;
    activePageIndex;
    pages;

    constructor() {
        this.domElement = document.getElementsByTagName("nav")[0];

        this.pages = [
            {key: "main", page: document.getElementById("mainMenuPage"), navItem: document.getElementById("navMain"), controller: APP},
            {key: "player", page: document.getElementById("playerSelectPage"), navItem: document.getElementById("navPlayer"), controller: APP.playerSelect},
            {key: "rules", page: document.getElementById("rulesSelectPage"), navItem: document.getElementById("navRules"), controller: APP.rulesSelect},
            {key: "game", page: document.getElementById("gamePage"), navItem: document.getElementById("navGame"), controller: APP.game}
        ];

        this.activePageIndex = 0;
        this.render();
    }

    render() {
        this.domElement.innerHTML = `
            <div id="navBack" class="navItem back noselect paper-btn btn-small border-3 ${this.getBackClass()}" onclick="APP.nav.back()"><span>${I18N("◀ ZURÜCK")}</span></div>
            <!--div>
              <div id="navMain" class="navItem ${this.activePage == "main" ? "active" : "hidden"}" onclick="APP.nav.select('main')">MAIN</div>
              <div id="navPlayer" class="navItem ${this.activePage == "player" ? "active" : "hidden"}" onclick="APP.nav.select('player')">PLAYER</div>
              <div id="navRules" class="navItem ${this.activePage == "rules" ? "active" : "hidden"}" onclick="APP.nav.select('rules')">RULES</div>
              <div id="navGame" class="navItem ${this.activePage == "game" ? "active" : "hidden"}" onclick="APP.nav.select('game')">GAME</div>
            </div-->
            <div id="navForward" class="navItem next noselect paper-btn btn-small border-5 ${this.getForwardClass()}" onclick="APP.nav.forward()">${I18N("WEITER ▶")}</div>
        `;
    }

    /**
     * Main nav-function. Handles page classes and triggers methods when loading
     * @param which Can be a number: go to page with that index. Can be a string: go to page with that key.
     */
    select(which) {
        if (isFinite(which))
            this.activePageIndex = which;
        else {
            const pageIndex = this.pages.findIndex(page => page.key == which);
            if (pageIndex > -1)
                this.activePageIndex = pageIndex;
        }

        this.render();

        for (let i=0; i <= this.activePageIndex; i++) {
            this.pages[i].page.classList.remove("active");
            this.pages[i].page.classList.remove("right");
        }

        this.pages[this.activePageIndex].page.classList.add("active");

        for (let i=this.activePageIndex+1; i < this.pages.length; i++) {
            this.pages[i].page.classList.remove("active");
            this.pages[i].page.classList.add("right");
        }

        const pageController = this.pages[this.activePageIndex].controller;
        pageController && pageController.onLoad && pageController.onLoad();
    }

    back() {
        if (this.activePageIndex > 0)
            this.select(this.activePageIndex-1);
    }

    forward() {
        if (this.activePageIndex < this.pages.length-1)
            this.select(this.activePageIndex+1);
    }

    getBackClass() {
        return this.activePageIndex > 0 ? "" : "invisible";
    }

    getForwardClass() {
        if (this.pages[this.activePageIndex].key == "player" && APP.playerSelect.getSelectedPlayers().length < 2)
            return "deactivated";

        return this.activePageIndex < this.pages.length-1 ? "" : "invisible";
    }

}