import Konva from 'konva';

export default class GameCanvas extends Konva.Stage {
    game;
    layer;
    circles;

    static SIZE = {
        x: 120,
        y: 100
    }
    CIRCLE_GRADIENTS = {
        "normal": [0, "#eeddaa", 0, "#e8bc62", 1, "#b39859"],
        "zap": [0, "#faf084", 0, "#ffff4f", 1, "#eeea08"],
        "finish": [0, "#aeee88", 0, "#63bb34", 1, "#008000"],
        "othersFinish": [0, "#f56666", 0, "#f1393d", 1, "#ee1000"],
    };

    constructor(game) {
        super({
            container: 'gameCanvas',
            width: GameCanvas.SIZE.x,
            height: GameCanvas.SIZE.y
        });
        this.game = game;

        this.resize();
        window.addEventListener('resize', () => this.resize());

        this.layer = new Konva.Layer();
        this.getStage().add(this.layer);

        this.initCircles();
    }

    initCircles() {
        const r = 11;
        const v = 1.77;
        const originalCircle = new Konva.Circle({
            x: 0,
            y: 0,
            radius: r,
            // fill: '#eeddaa',
            fillRadialGradientStartPoint: { x: 0, y: 0 },
            fillRadialGradientStartRadius: 0,
            fillRadialGradientEndPoint: { x: 0, y: 0 },
            fillRadialGradientEndRadius: r,
            fillRadialGradientColorStops: this.CIRCLE_GRADIENTS.normal,
            stroke: 'black',
            strokeWidth: 1
        });
        const data = [
            {n: 7,  x: -r*2,    y: -r*1.5*v},
            {n: 9,  x: 0,       y: -r*1.5*v},
            {n: 8,  x: +r*2,    y: -r*1.5*v},

            {n: 5,  x: -r*3,    y: -r*0.5*v},
            {n: 11, x: -r,      y: -r*0.5*v},
            {n: 12, x: +r,      y: -r*0.5*v},
            {n: 6,  x: +r*3,    y: -r*0.5*v},

            {n: 3,  x: -r*2,    y: +r*0.5*v},
            {n: 10, x: 0,       y: +r*0.5*v},
            {n: 4,  x: +r*2,    y: +r*0.5*v},

            {n: 1,  x: -r,      y: +r*1.5*v},
            {n: 2,  x: +r,      y: +r*1.5*v},

            {n: 0,  x: +4*r,    y: +r*1.7*v}
        ];
        this.circles = data.sort((a,b) => a.n - b.n).map(data => {
            const circleGroup = new Konva.Group({
                x: GameCanvas.SIZE.x/2 + data.x,
                y: GameCanvas.SIZE.y/2.2 + data.y
            });
            const circle = originalCircle.clone();
            circle.on('click tap', () => {
                this.game.clickOnWoodHandler(data.n);
                const circle = this.circles[data.n];
                const animationDuration = 500;

                const anim = new Konva.Animation(function(frame) {
                    const progress = Math.max(Math.min(frame.time / animationDuration, 1), 0);
                    let dist = 2*Math.pow(progress, 0.4);
                    if (dist > 1) {
                        dist = 2 - dist;
                    }
                    const gradientStops = circle.fillRadialGradientColorStops();
                    gradientStops[2] = dist;
                    circle.fillRadialGradientColorStops(gradientStops);
                }, this.layer);

                anim.start();
                setTimeout(() => {
                    anim.stop();
                }, animationDuration);
            });
            circleGroup.add(circle);
            circleGroup.add(new Konva.Text({
                x: -r,
                y: -r,
                width: r*2,
                height: r*2,
                text: data.n,
                fontSize: r*0.75,
                align: 'center',
                verticalAlign: 'middle',
                fontFamily: 'Arial',
                fill: '#000',
                listening: false
            }));
            this.layer.add(circleGroup);
            return circle;
        });
        this.layer.draw();
    }

    markCircles(zap, finish, othersFinish) {
        zap = zap || [];
        finish = finish || [];
        othersFinish = othersFinish || [];

        this.circles.forEach((circle, index) => {
            let gradient = this.CIRCLE_GRADIENTS.normal;
            if (zap.indexOf(index+1) > -1) {
                gradient = this.CIRCLE_GRADIENTS.zap;
            } else if (finish.indexOf(index+1) > -1) {
                gradient = this.CIRCLE_GRADIENTS.finish;
            } else if (othersFinish.indexOf(index+1) > -1) {
                gradient = this.CIRCLE_GRADIENTS.othersFinish;
            }
            circle.fillRadialGradientColorStops(gradient);
        });
    }

    resize() {
        const stage = this.getStage();
        const container = stage.container();
        const scale = Math.min(container.offsetWidth, container.offsetHeight) / 100;

        stage.width(GameCanvas.SIZE.x * scale);
        stage.height(GameCanvas.SIZE.y * scale);
        stage.scale({ x: scale, y: scale });
    }

    reset() {
        this.markCircles();
    }
}