import Player from "./Player";
import avatarImg from "url:../img/avatar.jpg";
import plusImg from "url:../img/plus.png";

export default class PlayerSelect {
    savedPlayers;

    constructor() {
        this.loadSavedPlayers();
    }

    render() {
        let html = this.savedPlayers.map(player => player.renderPlayerSelect()).join(" ");
        html += this.renderNewPlayerButton();

        document.getElementById("savedPlayers").innerHTML = html;
        if (this.savedPlayers.length > 5)
            document.getElementById("savedPlayers").classList.add("small");
        if (this.savedPlayers.length > 9)
            document.getElementById("savedPlayers").classList.add("smaller");
        if (this.savedPlayers.length > 13)
            document.getElementById("savedPlayers").classList.add("smallest");
    }

    renderNewPlayerButton() {
        return `
            <div class="player" onclick="APP.playerSelect.showNewPlayerDialog()">
                <div class="picture"><img src="${plusImg}"></div>
                <div class="name">${I18N("NEUER SPIELER")}</div>
            </div>`;
    }

    playerSelectClick(savedPlayersIndex) {
        const selectedPlayer = this.savedPlayers[savedPlayersIndex];
        selectedPlayer.setSelected(!selectedPlayer.isSelected);
        this.render();
        APP.nav.render();
    }

    showNewPlayerDialog() {
        document.getElementById("newPlayerPicture").src = avatarImg;
        document.getElementById("newPlayerNameInput").value = "";
        document.getElementById("newPlayerDialog").classList.add("show");
    }

    closeNewPlayerDialog() {
        document.getElementById("newPlayerDialog").classList.remove("show");
    }

    addNewPlayer() {
        const nameInput = document.getElementById("newPlayerNameInput");
        const picture = document.getElementById("newPlayerPicture");
        if (nameInput.value.length < 1) {
            nameInput.classList.add("error");
            setTimeout(() => nameInput.classList.remove("error"), 3000);
            return;
        }


        document.getElementById("newPlayerDialog").classList.remove("show");
        this.savedPlayers.push(new Player(nameInput.value, picture.src));
        this.updateSavedPlayers();

        this.saveSavedPlayers();
    }

    getSelectedPlayers() {
        return this.savedPlayers.filter(player => player.isSelected);
    }

    saveSavedPlayers() {
        if (window.caches) {
            caches.open('molkky-cache').then(cache => {
                cache.put("savedPlayers", new Response(JSON.stringify(this.savedPlayers)));
            });
        } else {
            localStorage.setItem("savedPlayers", JSON.stringify(this.savedPlayers));
        }
    }

    loadSavedPlayers() {
        if (window.caches) {
            caches.open("molkky-cache").then(cache => {
                cache.match("savedPlayers").then(response => {
                    this.savedPlayers = [];
                    if (response) {
                        response.json().then(data => {
                            this.savedPlayers = data.map(playerData => new Player(playerData.name, playerData.pictureUrl));
                            this.updateSavedPlayers();
                        });
                    } else {
                        this.render();
                    }
                });
            });
        } else {
            this.savedPlayers = JSON.parse(localStorage.getItem("savedPlayers")).map(playerData => new Player(playerData.name, playerData.pictureUrl)) || [];
            this.updateSavedPlayers();
        }
    }

    updateSavedPlayers() {
        this.savedPlayers.forEach((player, index) => player.savedPlayersIndex = index);
        this.render();
    }

    readNewPlayerPicture = (input) => {
        const url = input.value;
        const ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
        if (input.files && input.files[0] && (ext == "jpg" || ext == "jpeg" || ext == "gif" || ext == "png")) {
            const reader = new FileReader();

            reader.onload = (e) => {
                let imgUrl = e.target.result;
                const tmpImage = new Image();
                tmpImage.onload = () => {
                    const width = tmpImage.naturalWidth;
                    const height = tmpImage.naturalHeight;
                    const xStart = Math.max(width - height, 0) / 2;
                    const yStart = Math.max(height - width, 0) / 2;
                    const xLength = width - xStart*2;
                    const yLength = height - yStart*2;

                    const tmpCanvas = document.createElement("canvas");
                    tmpCanvas.width=192;
                    tmpCanvas.height=192;
                    const ctx = tmpCanvas.getContext('2d');
                    ctx.fillStyle = "#ffffff";
                    ctx.fillRect(0, 0, 192, 192);
                    ctx.drawImage(tmpImage, xStart, yStart, xLength, yLength, 0, 0, 192, 192);
                    document.getElementById("newPlayerPicture").src = tmpCanvas.toDataURL("image/jpeg", 0.88);
                };
                tmpImage.src = imgUrl;
            }
            reader.readAsDataURL(input.files[0]);
        } else {
            alert("Das scheint kein Bild zu sein.");
        }
    }

}