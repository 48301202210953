import {html, css, LitElement} from 'lit';

export class RulesSelect extends LitElement {
    rules = {
        requiredPoints: {
            value: 50
        },

        overThrowPunished: {
            enabled: true,
            type: "dropTo",
            value: 25
        },

        xZero: {
            enabled: true,
            checkType: "inRow", // "inRow", "total"
            type: "dropTo",
            value: 25
        },

        xZeroTimes: {
            value: 3
        },

        zapping: {
            enabled: true,
            type: "dropTo",
            value: 25
        }
    }

    static get styles() {
        return css`
            .oneline {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                line-height: 1.8em;
            }
            
            select {
                color: #41403e;
                background: transparent;
                border-bottom-left-radius: 15px 255px;
                border-bottom-right-radius: 225px 15px;
                border-top-left-radius: 255px 15px;
                border-top-right-radius: 15px 225px;
                border: 2px solid #41403e;
                display: block;
                font-size: 0.9em;
                outline: none;
                padding: .15rem;
                font-family: Neucha,sans-serif;
            }
            
            input {
                width: 1.25em;
                height: 1.25em;
            }
        `;
    }

    static get properties() {
        return {
            value: {type: Number},
            min: {type: Number},
            max: {type: Number}
        }
    }

    constructor() {
        super();
        this.value = 0;
    }

    renderRequiredPoints() {
        return html`
            <div class="oneline">
                ${I18N("Siegpunkte")}
                <number-input id="requiredPointsValue" .value="${this.rules.requiredPoints.value}" @change=${e => this.rules.requiredPoints.value = e.target.value} min="1" max="1000"></number-input>
            </div>
            <hr>
        `;
    }
    renderOverthrow() {
        return html`
            <div class="oneline">
                ${I18N("Drüber werfen bestrafen")}
                <input type="checkbox" .checked="${this.rules.overThrowPunished.enabled}" @change=${e => { this.rules.overThrowPunished.enabled = e.target.checked; this.requestUpdate(); }}/>
            </div>
            ${ this.rules.overThrowPunished.enabled ? html`
                <div class="oneline">
                    ${I18N("Falle auf")}
                    <number-input .value="${this.rules.overThrowPunished.value}" @change=${e => this.rules.overThrowPunished.value = e.target.value} min="1" max="1000"></number-input>
                </div>
            ` : ``}
            <hr>
        `;
    }
    renderXZero() {
        return html`
            <div class="oneline">
                ${I18N("Fehlwürfe bestrafen")}
                <input type="checkbox" .checked="${this.rules.xZero.enabled}" @change=${e => { this.rules.xZero.enabled = e.target.checked; this.requestUpdate(); }}/>
            </div>
            ${ this.rules.xZero.enabled ? html`
                <div class="oneline">
                    ${I18N("Anzahl Fehlwürfe")}
                    <number-input id="xZeroTimesValue" .value="${this.rules.xZeroTimes.value}" @change=${e => this.rules.xZeroTimes.value = e.target.value} min="1" max="10"></number-input>
                </div>
                <div class="oneline">
                    ${I18N("Wie gezählt?")}
                    <select @change=${e => {this.rules.xZero.checkType = e.target.value; this.requestUpdate(); }}>
                        <option value="inRow">${I18N("hintereinander")}</option>
                        <option value="total">${I18N("insgesamt")}</option>
                    </select>
                </div>
                <div class="oneline">
                    ${I18N("Was passiert?")}
                    <select @change=${e => {this.rules.xZero.type = e.target.value; this.requestUpdate(); }}>
                        <option value="dropTo">${I18N("Auf Punkte fallen")}</option>
                        <option value="dropBy">${I18N("Punkte verlieren")}</option>
                        <option value="elimination">${I18N("Rausfliegen")}</option>
                    </select>
                </div>
                ${ this.rules.xZero.type == "dropTo" || this.rules.xZero.type == "dropBy" ? html`
                    <div class="oneline">
                        ${I18N("Punkte")}
                        <number-input id="xZeroValue" .value="${this.rules.xZero.value}" @change=${e => this.rules.xZero.value = e.target.value} min="1" max="1000"></number-input>
                    </div>
                `: ``}
            `: ``}
            <hr>
        `;
    }
    renderZapping() {
        return html`    
            <div class="oneline">
                ${I18N("Zappen bei Gleichstand")}
                <input type="checkbox" .checked="${this.rules.zapping.enabled}" @change=${e => { this.rules.zapping.enabled = e.target.checked; this.requestUpdate(); }}/>
            </div>
            ${ this.rules.zapping.enabled ? html`
                <div class="oneline">
                    ${I18N("Was passiert?")}
                    <select @change=${e => {this.rules.zapping.type = e.target.value; this.requestUpdate(); }}>
                        <option value="dropTo">${I18N("Auf Punkte fallen")}</option>
                        <option value="dropBy">${I18N("Punkte verlieren")}</option>
                        <option value="swap">${I18N("Platz tauschen")}</option>
                        <option value="elimination">${I18N("Rausfliegen")}</option>
                    </select>
                </div>
                
                ${ this.rules.zapping.type == "dropTo" || this.rules.zapping.type == "dropBy" ? html`
                    <div class="oneline">
                        ${I18N("Punkte")}
                        <number-input id="zappingValue" .value="${this.rules.zapping.value}" @change=${e => this.rules.zapping.value = e.target.value}  min="1" max="1000"></number-input>
                    </div>
                `:``}
            `:``}
        `;
    }
    render() {
        return html`
                ${this.renderRequiredPoints()}
                ${this.renderOverthrow()}
                ${this.renderXZero()}
                ${this.renderZapping()}
                <br>
    `;
    }
}

customElements.define('rules-page', RulesSelect);