import Game from "./Game";
import Nav from "./Nav";
import PlayerSelect from "./PlayerSelect";
import RulesSelect from "./RulesSelect";
import NumberInput from "./NumberInput";

function init() {
    window.I18N = (what) => {
        return what;
    }

    window.APP = {};
    APP.playerSelect = new PlayerSelect();
    APP.rulesSelect = document.querySelector("rules-page");

    APP.game = new Game();
    APP.game.render();

    APP.nav = new Nav();


    const i18nNodes = document.getElementsByClassName("i18n");
    for (let i = 0; i < i18nNodes.length; i++)
        i18nNodes[i].innerText = I18N(i18nNodes[i].innerText);
};
init();