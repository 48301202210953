export default class Player {
    savedPlayersIndex;
    gamePlayersIndex;
    pictureUrl;
    name;
    scores;
    points;
    isSelected;
    isCurrent;
    isFinished;
    pointsLastRound;

    constructor(name, pictureUrl) {
        this.savedPlayersIndex = 0;
        this.gamePlayersIndex = 0;
        this.pictureUrl = pictureUrl;
        this.name = name;
        this.isCurrent = false;
        this.isSelected = false;
        this.resetPoints();
    }

    setCurrent(isCurrent) {
        this.isCurrent = isCurrent;
    }

    setSelected(isSelected) {
        this.isSelected = isSelected;
    }

    setFinished(isFinished) {
        this.isFinished = isFinished;
    }

    resetPoints() {
        this.scores = [];
        this.points = 0;
        this.pointsLastRound = 0;
        this.setFinished(false);
    }

    dropPoints(type, points) {
        if (type == "dropTo") {
            if (this.points >= points)
                this.points = points;
            else
                this.points = 0;
        } else if (type == "dropBy") {
            this.points -= points;
            this.points = Math.max(this.points, 0);
        }
    }

    saveScore(n) {
        this.scores.push(n);
        this.pointsLastRound = this.points;
        this.points += n;
    }

    deleteLastScore() {
        const oldScore = this.scores.pop();
        this.points = this.pointsLastRound;
    }

    renderGame() {
        const isEditMode = APP.game.editMode;
        const scoreMarked = isEditMode ? this.gamePlayersIndex == APP.game.editPlayer.gamePlayersIndex : this.isCurrent;
        return `
<div class="player border ${this.isCurrent ? "current" : ""}">
    <div class="picture"><img src="${this.pictureUrl}"></div>
    <div class="nameScores">
        <div class="name">${this.name}</div>
        <div class="scores">
            ${this.scores.length > 0 ? `<span>${this.scores.join("</span><span>")}</span>` : ""} 
            ${scoreMarked ? `<span class="current">?</span>` : ``}
            ${!this.isCurrent && !isEditMode && this.scores.length > 0 ? `
                <span class="edit" onclick="APP.game.editScore(${this.gamePlayersIndex})">
                    <svg viewBox="0 0 32 32">
                        <path d="m 27.842758,0.0639881 c -1.046875,0 -2.09375,0.40625 -2.90625,1.21875 L 9.1240079,17.095238 l -0.0625,0.3125 -1.09375,5.5 -0.3125,1.46875 1.46875,-0.3125 5.5000001,-1.09375 0.3125,-0.0625 15.8125,-15.8124999 c 1.625,-1.625 1.625,-4.1875 0,-5.8125 -0.8125,-0.8125 -1.859375,-1.21875 -2.90625,-1.21875 z m 0,1.90625 c 0.503906,0 1.011719,0.230469 1.5,0.71875 0.972656,0.972656 0.972656,2.027344 0,3 l -0.71875,0.6875 -2.96875,-2.96875 0.6875,-0.71875 c 0.488281,-0.488281 0.996094,-0.71875 1.5,-0.71875 z m -3.59375,2.84375 2.96875,2.96875 -12.09375,12.0937499 c -0.65625,-1.28125 -1.6875,-2.3125 -2.96875,-2.96875 z m -13.375,13.7187499 c 1.199219,0.484375 2.140625,1.425781 2.625,2.625 l -3.28125,0.65625 z" />
                    </svg>
                </span>` : ``}
        </div>
    </div>
    <div class="points">${this.points}</div>
</div>
        `;
    }

    renderPlayerSelect() {
        return `
<div class="player ${this.isSelected ? "selected" : ""}" onclick="APP.playerSelect.playerSelectClick(${this.savedPlayersIndex})">
    <div class="picture"><img src="${this.pictureUrl}"></div>
        <div class="name border-2">${this.name}</div>
    </div>
</div>
        `;
    }

    toString() {
        return `Player ${this.savedPlayersIndex}: ${this.name} | points: ${this.points}`;
    }
}